import React, { useState, useEffect, useContext } from 'react'
import { useSession } from 'src/sdk/session'
import { useProductAbTestEvent } from 'src/sdk/analytics/hooks/useProductAbTestEvent'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import { HeaderSizeContext } from 'src/Layout'
import { isCategoryFromListCategories } from 'src/utils/isCategoryFromListCategories'
import { isNewPDP } from 'src/utils/isNewPDP'
import { Button } from '@faststore/ui'

import AlertWithMessage from '../common/AlertWithMessage'
import Rings from './Rings/Rings'
import MeasureGuideModal from './MeasureGuideModal/MeasureGuideModal'
import Bracelet from './Bracelet/Bracelet'
import Necklace from './Necklace/Necklace'

import './measure-guide.scss'

interface MeasureGuideProps {
  categoryTree: IcategoryTree
  isKit?: boolean
}

type IcategoryTree = Array<{
  item: string
  name: string
  position: number
}>

const getTypeModal = (categoryTree: IcategoryTree) => {
  const isRing = categoryTree?.some(
    ({ item: category }) =>
      category?.includes('anel') ||
      category?.includes('aneis') ||
      category?.includes('alianca')
  )

  const isBracelet = categoryTree?.some(({ item: category }) =>
    category?.includes('pulseira')
  )

  const isNotBracelet = categoryTree?.some(
    ({ item: category }) =>
      category?.includes('bracelete') ||
      category?.includes('men') ||
      category?.includes('masculina') ||
      category?.includes('masculino')
  )

  const isNecklace = categoryTree?.some(
    ({ item: category }) =>
      category?.includes('corrente') || category?.includes('colar')
  )

  if (isRing) {
    return 'ring'
  }

  if (isBracelet && !isNotBracelet) {
    return 'bracelet'
  }

  if (isNecklace) {
    return 'necklace'
  }

  return null
}

const MeasureGuide = ({ categoryTree, isKit }: MeasureGuideProps) => {
  const [showButton, setShowButton] = useState<boolean>(false)
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [typeModal, setTypeModal] = useState<string>('')
  const [showAlertMessage, setShowAlertMessage] = useState<boolean>(false)
  const { person } = useSession()
  const { isMobile } = useWindowDimensions()
  const { sendProductAbTestEvent } = useProductAbTestEvent()
  const headerSizeContext = useContext(HeaderSizeContext)
  const isLife = headerSizeContext?.isLife === true
  const MEASURE_TOOLTIP_TAG = 'shouldRenderMeasureTooltip'

  const headerBar = document.querySelector(
    '.header-bar-container'
  ) as HTMLElement

  useEffect(() => {
    const productType = getTypeModal(categoryTree)

    if (productType !== null) {
      setShowButton(true)
      setTypeModal(productType)
    }

    const shouldRenderMeasureTooltip =
      localStorage.getItem(MEASURE_TOOLTIP_TAG) === null

    setShowAlertMessage(
      isCategoryFromListCategories(categoryTree, ['aneis', 'aliancas']) &&
        shouldRenderMeasureTooltip
    )
  }, [categoryTree])

  const openModal = () => {
    setIsOpenModal(true)

    if (headerBar && isMobile) {
      headerBar.style.display = 'none'
    }
  }

  const closeModal = () => {
    setIsOpenModal(false)

    if (headerBar && isMobile) {
      headerBar.style.display = 'flex'
    }
  }

  const sendSelectItemAbTestEventToAnalytics = () => {
    sendProductAbTestEvent({
      pdp_version: isNewPDP ? 'versao_2' : 'versao_1',
      action: 'click',
      component: 'botao_sublinhado',
      position_interaction: 'direita',
      element_name: 'guia_de_medidas',
      section: 'info_gerais',
      user_id: person?.id ?? null,
      user_logged: !!person?.id,
    })
  }

  return (
    <>
      {showButton && (
        <div className="a-measureGuide__button">
          {showAlertMessage && !isKit && !isNewPDP && (
            <AlertWithMessage
              text="NEW! Descubra o tamanho do seu anel"
              measureTag={MEASURE_TOOLTIP_TAG}
            />
          )}
          <Button
            id="btn-new-measure-guide"
            className="measureGuide__button"
            onClick={() => {
              openModal()
              sendSelectItemAbTestEventToAnalytics()
            }}
          >
            Guia de medidas
          </Button>
        </div>
      )}

      {isOpenModal && typeModal === 'ring' && (
        <MeasureGuideModal closeModal={closeModal} title="Anéis">
          <Rings isLife={isLife} />
        </MeasureGuideModal>
      )}

      {isOpenModal && typeModal === 'bracelet' && (
        <MeasureGuideModal closeModal={closeModal} title="Pulseiras">
          <Bracelet />
        </MeasureGuideModal>
      )}

      {isOpenModal && typeModal === 'necklace' && (
        <MeasureGuideModal closeModal={closeModal} title="Colares">
          <Necklace />
        </MeasureGuideModal>
      )}
    </>
  )
}

export default MeasureGuide
