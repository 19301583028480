import type { BrowserProductQueryQuery } from '@generated/graphql'
import React, { useEffect, useMemo, useState } from 'react'
import { useDetailsSpecificationsContext } from 'src/contexts/details-specification-context'
import type { AnchorOffset } from 'src/components/sections/ProductDescription/AnchorMenu'
import type { Specifications } from 'src/contexts/details-specification-context'

import MenuButtons from './MenuButtons'
import AboutCollection from './AboutCollection'
import ProductTabDetails from './ProductTabDetails'

import './styles.scss'

interface ProductInfoTabProps {
  isPDPLife: boolean
  product: BrowserProductQueryQuery['product']
  anchorOffset?: AnchorOffset
}

export interface Collections {
  name?: string | null
  description?: string | null
  urlImage?: string | null
  altImage?: string | null
  id?: string | null
}

function getSpecifications(specifications: Specifications[]) {
  return specifications.filter(
    (el) =>
      el.type === 'Material' ||
      el.type === 'Pedra' ||
      el.type === 'Malha' ||
      el.type === 'Mecanismos' ||
      el.type === 'Resistência' ||
      el.type === 'Material do vidro' ||
      el.type === 'Material da pulseira'
  )
}

function getCollections(specifications: Specifications[], isPDPLife: boolean) {
  return specifications
    ?.filter((item) => item?.type === 'Inspiração')
    ?.map((collection) => {
      const {
        name,
        description,
        url_image: urlImage,
        url_image_life: urlImageLife,
        id,
        description_life: descriptionLife,
        alt_image: altImage,
        alt_image_life: altImageLife,
      } = collection

      return {
        name,
        description: isPDPLife ? descriptionLife ?? description : description,
        urlImage: isPDPLife ? urlImageLife ?? urlImage : urlImage,
        altImage: isPDPLife ? altImageLife ?? altImage : altImage,
        id,
      }
    })
}

const ProductInfoTab = (props: ProductInfoTabProps) => {
  const { isPDPLife, product } = props
  const [isOpen, setIsOpen] = useState(false)
  const [tabView, setTabView] = useState('')
  const { detailsSpecifications } = useDetailsSpecificationsContext()

  const collections = useMemo(() => {
    return getCollections(detailsSpecifications, isPDPLife)
  }, [detailsSpecifications, isPDPLife])

  const productSpecifications = getSpecifications(detailsSpecifications)
  const hasCollections = collections?.length > 0
  const hasProductSpecifications = productSpecifications?.length > 0
  const hasDescription = product?.description?.length > 0
  const hasSomeInfo =
    hasCollections || hasProductSpecifications || hasDescription

  const isGift = product?.productClusters?.some((cluster) => {
    return cluster?.name === 'Cartão Presente'
  })

  const getActualTab = (tabName: string) => tabView === tabName && isOpen

  useEffect(() => {
    const values = [
      { value: 'collection', has: hasCollections },
      { value: 'details', has: hasProductSpecifications },
      { value: 'description', has: hasDescription },
      { value: '', has: true },
    ]

    setTabView(values?.filter((value) => value?.has)[0].value)
  }, [])

  return hasSomeInfo ? (
    <section className="product-info-tab">
      <MenuButtons
        collections={collections}
        productSpecifications={productSpecifications}
        product={product}
        tabView={tabView}
        setTabView={setTabView}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isGift={isGift}
      />
      <div className="product-info-tab__data">
        {getActualTab('collection') && (
          <AboutCollection collections={collections} isPDPLife={isPDPLife} />
        )}
        {getActualTab('details') && (
          <ProductTabDetails
            isGift={isGift}
            productSpecifications={productSpecifications}
            isPDPLife={isPDPLife}
          />
        )}
        {getActualTab('description') && (
          <div className="description">{product?.description}</div>
        )}
      </div>
    </section>
  ) : (
    <></>
  )
}

export default ProductInfoTab
