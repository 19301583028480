import React from 'react'
import ProductDetailsExpandableContainer from 'src/components/sections/ProductDescription/ProductDetailsExpandableContainer'
import type { Specifications } from 'src/contexts/details-specification-context'

import DetailsOfProductGivex from '../DetailsOfProductGivex'

interface ProductTabDetailsProps {
  isGift?: boolean
  isPDPLife: boolean
  productSpecifications: Specifications[]
}

const ProductTabDetails = (props: ProductTabDetailsProps) => {
  const { isGift, productSpecifications, isPDPLife } = props

  return (
    <>
      {isGift ? (
        <DetailsOfProductGivex />
      ) : (
        <ProductDetailsExpandableContainer
          productSpecifications={productSpecifications}
          isPDPLife={isPDPLife}
        />
      )}
    </>
  )
}

export default ProductTabDetails
