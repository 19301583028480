import type {
  BrowserProductQueryQuery,
  Specifications,
  IStoreSelectedFacet,
} from '@generated/graphql'

import { generateMeasurements } from './generateMeasures'
import getProductSpecGroup from './getProductSpecGroup'

const SPECIFICATION_GROUP_NAMES: Record<string, string> = {
  espessura: 'Espessura aprox',
  material: 'Material',
  largura: 'Largura aprox',
  pedras: 'Pedras',
}

const FACETS_KEYS = {
  espessura: 'espessura-aprox',
  material: 'material',
  largura: 'largura-aprox',
  pedras: 'pedras',
  category: 'category-3',
  price: 'price',
}

const SELECTED_SPECIFICATION_GROUPS = [
  'espessura',
  'largura',
  'pedras',
  'material',
]

const PRICE_THRESHOLD = 2790
const HIGH_PERCENTAGE_RATE = 0.4
const LOW_PERCENTAGE_RATE = 0.2

const getPriceFacets = (
  offers: BrowserProductQueryQuery['product']['offers']
) => {
  const offersPrices = offers?.offers?.[0]
  const price =
    offersPrices?.availability === 'https://schema.org/InStock'
      ? offers?.lowPrice
      : offersPrices?.price

  if (!price) return

  const percentageRate =
    price <= PRICE_THRESHOLD ? HIGH_PERCENTAGE_RATE : LOW_PERCENTAGE_RATE

  const calculationPerc = price * percentageRate
  const minPrice = price - calculationPerc
  const maxPrice = price + calculationPerc

  return {
    key: FACETS_KEYS.price,
    value: `${minPrice}-to-${maxPrice}`,
  }
}

const getSelectedSpecificationGroups = (
  specificationGroups: BrowserProductQueryQuery['product']['specificationGroups']
) => {
  return SELECTED_SPECIFICATION_GROUPS.map((item) => {
    const specification = getProductSpecGroup(
      specificationGroups,
      SPECIFICATION_GROUP_NAMES[item]
    )

    if (!specification) return

    return specification
  }).filter(Boolean)
}

const getCategoryFacets = (categoryThree: string) => {
  if (!categoryThree) return

  return { key: FACETS_KEYS.category, value: categoryThree }
}

const getMeasurementFacets = (
  selectedFacets: Array<Specifications | undefined>,
  measurement: 'largura' | 'espessura'
) => {
  if (!selectedFacets || !selectedFacets.length) return null

  const facet = selectedFacets.find(
    (item) => item?.name === SPECIFICATION_GROUP_NAMES[measurement]
  )

  if (!facet) return null

  const measurementsArray = generateMeasurements(facet)

  if (!measurementsArray || !measurementsArray.length) return null

  return measurementsArray?.map((value) => {
    return {
      key: FACETS_KEYS[measurement],
      value,
    }
  })
}

const getMaterialFacets = (
  selectedFacets: Array<Specifications | undefined>
) => {
  const materialValue = selectedFacets.find(
    (facet) => facet?.name === SPECIFICATION_GROUP_NAMES.material
  )?.values?.[0]

  const formattedMaterialValue = materialValue
    ? materialValue.toLocaleLowerCase().replaceAll(' ', '-')
    : undefined

  if (!formattedMaterialValue) return null

  return { key: FACETS_KEYS.material, value: formattedMaterialValue }
}

const getPedraFacets = (selectedFacets: Array<Specifications | undefined>) => {
  const pedraValue = selectedFacets.find(
    (facet) => facet?.name === SPECIFICATION_GROUP_NAMES.pedras
  )?.values?.[0]

  const formattedPedraValue = pedraValue
    ? pedraValue.toLocaleLowerCase().replaceAll(' ', '-')
    : undefined

  if (!formattedPedraValue) return null

  return { key: FACETS_KEYS.pedras, value: formattedPedraValue }
}

export const getComparedProductByVisual = (
  categoryThree: string,
  specificationGroups: BrowserProductQueryQuery['product']['specificationGroups'],
  offers: BrowserProductQueryQuery['product']['offers']
) => {
  const selectedSpecificationGroups =
    getSelectedSpecificationGroups(specificationGroups)

  const facetsFunctions = {
    price: getPriceFacets(offers),
    category: getCategoryFacets(categoryThree),
    espessura: getMeasurementFacets(selectedSpecificationGroups, 'espessura'),
    largura: getMeasurementFacets(selectedSpecificationGroups, 'largura'),
    material: getMaterialFacets(selectedSpecificationGroups),
    pedras: getPedraFacets(selectedSpecificationGroups),
  }

  const selectedFacets: IStoreSelectedFacet[] = Object.values(facetsFunctions)
    .flat()
    .filter(
      (facet): facet is IStoreSelectedFacet =>
        facet !== null && facet !== undefined
    )

  return selectedFacets
}
