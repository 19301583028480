import React, { useState } from 'react'
import type {
  BrowserProductQueryQuery,
  Filter_FacetsFragment,
  IStoreSelectedFacet,
  ProductSummary_ProductFragment,
} from '@generated/graphql'
import type { SearchSort } from '@faststore/sdk/dist/types'
import type { InferredType } from 'src/components/sections/ProductDetails/useOffer'
import ThemeTable from 'src/components/sections/ThemeTable/ThemeTable'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import { CustomFilterMobileButton } from 'src/components/CustomFilter/CustomFilterMobileButton'
import Filter from 'src/components/search/Filter'

import type { SelectedProductsAssemble } from './types'
import type { ClusterHighlights } from '../ProductCardCustomBracelet/ProductCardAssemble'
import { ModalTutorial } from '../ModalTutorial/ModalTutorial'
import { ProductGalleryAssemble } from '../ProductGalleryAssemble/ProductGalleryAssemble'
import { pixelPopUpExit } from '../AssemblePixel/AssemblePixelPopUpExit'
import { ExitModal } from '../AssemblePixel/ExitModal'
import { ReviewBracelet } from '../AssembleResume/ReviewBracelet'
import { AccordionAssembly } from './AccordionAssembly'
import {
  addCardProductChain,
  addCardProduct,
  cancelEditFunc,
  onClickRemoveProductFunc,
  isItemSelected,
  changeShowModal,
} from './AssemblyUtils'

import './AssemblyPage.scss'

export const DEFAULT_MAX_ADDITIONAL_ITENS = 11

interface ProductCardCustomBraceletProps {
  productMain: BrowserProductQueryQuery['product']
  selectedSkuBracelet: InferredType<
    BrowserProductQueryQuery['product']['isVariantOf']['hasVariant']
  >
}

const Assembly = ({
  productMain,
  selectedSkuBracelet,
}: ProductCardCustomBraceletProps) => {
  const [
    selectedProductsAssembleBracelet,
    setSelectedProductsAssembleBracelet,
  ] = useState<Array<SelectedProductsAssemble | null>>([])

  const [removedCardsWhileEditing, setremovedCardsWhileEditingg] = useState<
    Array<{ product: SelectedProductsAssemble; isChain: boolean }>
  >([])

  const [
    selectedProductsAssembleBraceletChain,
    setSelectedProductsAssembleBraceletChain,
  ] = useState<SelectedProductsAssemble | null>(null)

  const { isMobile } = useWindowDimensions()

  const hasChoiceChain = productMain.isVariantOf.additionalProperty.some(
    (propriety, _index) => {
      return (
        propriety.name.toLowerCase() === 'monte sua pulseira' &&
        propriety?.value.toLowerCase() === 'pulseira sem corrente'
      )
    }
  )

  const DEFAULT_FACETS: IStoreSelectedFacet[] = [
    { key: 'monte-sua-pulseira-acessorios', value: 'pingente' },
    { key: 'monte-sua-pulseira-acessorios', value: 'corrente' },
  ]

  const [selectedFacets, setSelectedFacets] = useState<IStoreSelectedFacet[]>([
    ...DEFAULT_FACETS,
  ])

  const [sort, setSort] = useState<SearchSort>('score_desc')

  const [itemsSelectedQtt, setItemsSelectedQtt] = useState<number>(0)

  const [filteredFacets, setFilteredFacets] = useState<Filter_FacetsFragment[]>(
    []
  )

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false)

  const [hasChainSelected, sethasChainSelected] = useState<boolean>(false)

  const getNewItemsSelectedQtt = () =>
    selectedProductsAssembleBracelet.filter((item) => !!item).length +
    (selectedProductsAssembleBraceletChain ? 1 : 0)

  const onClickAddChain = async (
    product: SelectedProductsAssemble
  ): Promise<void> => {
    addCardProductChain({
      product,
      setSelectedProductsAssembleBraceletChain,
      setItemsSelectedQtt,
      getNewItemsSelectedQtt,
      selectedProductsAssembleBraceletChain,
      hasChainSelected,
    })

    sethasChainSelected(true)
  }

  const onClickAddCardProduct = async (
    product: SelectedProductsAssemble
  ): Promise<number> =>
    addCardProduct({
      product,
      setSelectedProductsAssembleBracelet,
      setItemsSelectedQtt,
      getNewItemsSelectedQtt,
    })

  const finishEdit = () => setremovedCardsWhileEditingg([])

  const cancelEdit = () =>
    cancelEditFunc({
      removedCardsWhileEditing,
      onClickAddChain,
      onClickAddCardProduct,
      finishEdit,
    })

  const onClickRemoveProduct = async (index: number) =>
    onClickRemoveProductFunc({
      index,
      getNewItemsSelectedQtt,
      removedCardsWhileEditing,
      selectedProductsAssembleBraceletChain,
      sethasChainSelected,
      setItemsSelectedQtt,
      setSelectedProductsAssembleBracelet,
      setSelectedProductsAssembleBraceletChain,
    })

  const checkItemIsSelected = (
    product: ProductSummary_ProductFragment & ClusterHighlights
  ): boolean =>
    isItemSelected(
      product,
      selectedProductsAssembleBracelet,
      selectedProductsAssembleBraceletChain
    )

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const [getOutOfWindow, setGetOutOfWindow] = useState<{
    href: string
    innerText?: string
  }>({ href: '' })

  const [showExitModal, setShowExitModal] = useState<boolean>(false)

  pixelPopUpExit({ setGetOutOfWindow, setShowExitModal, isMobile })
  const modalOpen = () => changeShowModal(setIsOpen, 'open')

  const modalClose = () => changeShowModal(setIsOpen, 'close')

  const [isFirstClick, setIsFirstClick] = useState<boolean>(true)

  const applyChainFilter = () => {
    setSelectedFacets([
      ...DEFAULT_FACETS,
      { key: 'category-4', value: 'correntes-de-seguranca' },
    ])
  }

  return (
    <div className="assemble-component">
      <ModalTutorial isOpen />
      {isMobile && (
        <ReviewBracelet
          isOpen={isOpen}
          modalClose={modalClose}
          modalOpen={modalOpen}
          selectedProductsAssembleBracelet={selectedProductsAssembleBracelet}
          setSelectedProductsAssembleBracelet={setSelectedProductsAssembleBracelet}
          productMain={productMain}
          hasChoiceChain={hasChoiceChain}
          selectedProductsAssembleBraceletChain={
            selectedProductsAssembleBraceletChain
          }
          setSelectedProductsAssembleBraceletChain={setSelectedProductsAssembleBraceletChain}
          selectedSkuBracelet={selectedSkuBracelet}
          isFirstClick={isFirstClick}
          setIsFirstClick={setIsFirstClick}
          applyChainFilter={applyChainFilter}
        />
      )}

      <AccordionAssembly
        selectedProductsAssembleBracelet={selectedProductsAssembleBracelet}
        setSelectedProductsAssembleBracelet={setSelectedProductsAssembleBracelet}
        productMain={productMain}
        selectedSkuBracelet={selectedSkuBracelet}
        itemsSelectedQtt={itemsSelectedQtt}
        onClickAddCardProduct={onClickAddCardProduct}
        onClickRemoveProduct={onClickRemoveProduct}
        onClickAddChain={onClickAddChain}
        hasChoiceChain={hasChoiceChain}
        selectedProductsAssembleBraceletChain={
          selectedProductsAssembleBraceletChain
        }
        setSelectedProductsAssembleBraceletChain={setSelectedProductsAssembleBraceletChain}
        isFirstClick={isFirstClick}
        setIsFirstClick={setIsFirstClick}
        applyChainFilter={applyChainFilter}
        cancelEdit={cancelEdit}
        finishEdit={finishEdit}
      />
      {!isMobile && (
        <ThemeTable
          themeFacets={filteredFacets}
          setSelectedFacets={setSelectedFacets}
        />
      )}
      <div className="product-listing__filters">
        <Filter
          facets={filteredFacets}
          isOpen={isFilterOpen}
          setIsOpen={setIsFilterOpen}
          selectedFacets={selectedFacets}
          setSelectedFacets={setSelectedFacets}
          filterByUrl={false}
          term=""
          base=""
          defaultFacets={[
            { key: 'monte-sua-pulseira-acessorios', value: 'pingente' },
            { key: 'monte-sua-pulseira-acessorios', value: 'corrente' },
          ]}
        />
      </div>

      <CustomFilterMobileButton
        isFilterOpen={isFilterOpen}
        setIsFilterOpen={setIsFilterOpen}
        sort={sort}
        setSort={setSort}
      />

      <ProductGalleryAssemble
        selectedFacets={selectedFacets}
        onClickAddCardProduct={onClickAddCardProduct}
        onClickRemoveProduct={onClickRemoveProduct}
        setFilteredFacets={setFilteredFacets}
        checkItemIsSelected={checkItemIsSelected}
        sort={sort}
        onClickAddChain={onClickAddChain}
        hasChoiceChain={hasChoiceChain}
        hasChainSelected={hasChainSelected}
        selectedProductsAssembleBraceletChain={
          selectedProductsAssembleBraceletChain
        }
      />

      {showExitModal && (
        <ExitModal
          showExitModal={showExitModal}
          setShowExitModal={setShowExitModal}
          setGetOutOfWindow={setGetOutOfWindow}
          getOutOfWindow={getOutOfWindow}
        />
      )}
    </div>
  )
}

export default Assembly
