import React from 'react'
import type { BrowserProductQueryQuery } from '@generated/graphql'
import type { Specifications } from 'src/contexts/details-specification-context'
import { useSession } from 'src/sdk/session'
import { useProductAbTestEvent } from 'src/sdk/analytics/hooks/useProductAbTestEvent'
import { isNewPDP } from 'src/utils/isNewPDP'

import type { Collections } from './ProductInfoTab'

interface MenuButtonsProps {
  collections: Collections[]
  productSpecifications: Specifications[]
  product: BrowserProductQueryQuery['product']
  tabView: string
  setTabView: React.Dispatch<React.SetStateAction<string>>
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  isGift?: boolean
}

const MenuButtons = (props: MenuButtonsProps) => {
  const {
    collections,
    productSpecifications,
    product,
    tabView,
    setTabView,
    isOpen,
    setIsOpen,
    isGift,
  } = props

  const { person } = useSession()
  const { sendProductAbTestEvent } = useProductAbTestEvent()
  const hasCollections = collections?.length > 0
  const hasProductSpecifications = productSpecifications?.length > 0
  const hasDescription = product?.description

  const sendCollectionSelectItemAbTestEventToAnalytics = () => {
    sendProductAbTestEvent({
      pdp_version: isNewPDP ? 'versao_2' : 'versao_1',
      action: 'click_dropdown',
      component: 'dropdown',
      position_interaction: 'meio',
      element_name: 'colecao_do_produto',
      section: 'detalhamento_produto',
      user_id: person?.id ?? null,
      user_logged: !!person?.id,
    })
  }

  const sendDetailsSelectItemAbTestEventToAnalytics = () => {
    sendProductAbTestEvent({
      pdp_version: isNewPDP ? 'versao_2' : 'versao_1',
      action: 'click_dropdown',
      component: 'dropdown',
      position_interaction: 'meio',
      element_name: 'detalhes_do_produto',
      section: 'detalhamento_produto',
      user_id: person?.id ?? null,
      user_logged: !!person?.id,
    })
  }

  const sendDescriptionSelectItemAbTestEventToAnalytics = () => {
    sendProductAbTestEvent({
      pdp_version: isNewPDP ? 'versao_2' : 'versao_1',
      action: 'click_dropdown',
      component: 'dropdown',
      position_interaction: 'meio',
      element_name: 'descricao_do_produto',
      section: 'detalhamento_produto',
      user_id: person?.id ?? null,
      user_logged: !!person?.id,
    })
  }

  return (
    <div className="product-info-tab__menu-buttons">
      <div className="menu-button">
        {hasCollections && (
          <button
            className={`info-button collection ${
              tabView === 'collection' ? 'active' : ''
            }`}
            onClick={() => {
              setTabView('collection')
              setIsOpen(true)
              sendCollectionSelectItemAbTestEventToAnalytics()
            }}
          >
            COLEÇÃO
          </button>
        )}
        {(hasProductSpecifications || isGift) && (
          <button
            className={`info-button details ${
              tabView === 'details' ? 'active' : ''
            }`}
            onClick={() => {
              setTabView('details')
              setIsOpen(true)
              sendDetailsSelectItemAbTestEventToAnalytics()
            }}
          >
            DETALHES
          </button>
        )}
        {hasDescription && (
          <button
            className={`info-button description ${
              tabView === 'description' ? 'active' : ''
            }`}
            onClick={() => {
              setTabView('description')
              setIsOpen(true)
              sendDescriptionSelectItemAbTestEventToAnalytics()
            }}
          >
            DESCRIÇÃO
          </button>
        )}
      </div>
      <button
        className={`accordion-controller ${isOpen}`}
        onClick={() => setIsOpen(!isOpen)}
      />
    </div>
  )
}

export default MenuButtons
