import React, { memo, useEffect, useState, lazy, Suspense } from 'react'
import type P5Types from 'p5'
import { useFadeEffect } from 'src/sdk/ui/useFadeEffect'
import mainCanvasImage from 'src/images/life-lover/assemble-yor-bracelet/canvas.png'
import { Skeleton } from '@acctglobal/skeleton'
import ClientOnly from 'src/utils/ClientOnly'

import type { SelectedProductsAssemble } from '../Assembly/types'

import './style.scss'

interface AdditionalProductProps {
  imgSrc: P5Types.Image | null
  pos: number
}

interface ProductRenderProps {
  selectedProductsAssembleBracelet: Array<SelectedProductsAssemble | null>
  isMobileDimension: boolean | null
  widthDimension: number
}

const DEFAULT_WIDTH_MOBILECANVA_IMAGES = 85
const DEFAULT_WIDTH_DESKTOPCANVA_IMAGES = 143
const DEFAULT_IMAGE_WIDTH = 430
const DEFAULT_IMAGE_HEIGTH = 705

const ProductRender = ({
  selectedProductsAssembleBracelet,
  isMobileDimension,
  widthDimension,
}: ProductRenderProps) => {
  let mainProductImage: P5Types.Image
  const adittionalProductsImages: AdditionalProductProps[] = []
  const [width, setWith] = useState<number | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    if (widthDimension && !width) {
      setWith(widthDimension)
    }
  }, [width, widthDimension])

  useFadeEffect()

  const canvaDimensionWidth =
    isMobileDimension && width ? (width < 376 ? width : 376) : 692

  const canvaDimensionHeigth = isMobileDimension ? 376 : 692
  const canvaBraceletDimension = isMobileDimension ? 376 : 692

  const Sketch = lazy(() => import('react-p5'))

  const drawRotateImage = (
    canvasObject: P5Types,
    { imgSrc, pos }: AdditionalProductProps
  ) => {
    const anglePosition = [0, 25, -25, 47, -47, 70, -70, 98, -98, 122, -120]

    const xDesktopPosition = [
      49.5, 31, 68, 15.8, 83.3, 6.68, 92.83, 4.95, 95.5, 10.8, 90.5,
    ]

    const yDesktopPosition = [
      72.3, 68.6, 69, 58.5, 59.2, 42.9, 43.85, 24.18, 25.18, 7.4, 7.9,
    ]

    const xMobilePosition = [
      49.5, 31, 68, 14.7, 84.55, 5, 94.6, 3.8, 96.9, 10.2, 91,
    ]

    const yMobilePosition = [
      74, 70.45, 70.8, 59.7, 60.3, 42.8, 43.5, 23.75, 24.5, 5.5, 5.7,
    ]

    const xPosition = isMobileDimension ? xMobilePosition : xDesktopPosition
    const yPosition = isMobileDimension ? yMobilePosition : yDesktopPosition

    const imgWidth = isMobileDimension
      ? DEFAULT_WIDTH_MOBILECANVA_IMAGES
      : DEFAULT_WIDTH_DESKTOPCANVA_IMAGES

    const imgHeigth = (DEFAULT_IMAGE_HEIGTH * imgWidth) / DEFAULT_IMAGE_WIDTH

    const imgX =
      (xPosition[pos] * canvaBraceletDimension) / 100 +
      (canvasObject.width - canvaBraceletDimension) / 2 -
      imgWidth / 2

    const imgY =
      (yPosition[pos] * canvaBraceletDimension) / 100 +
      (canvasObject.height - canvaBraceletDimension) / 2 -
      imgHeigth / 2

    canvasObject.imageMode('center')
    canvasObject.translate(imgX + imgWidth / 2, imgY + imgWidth / 2)
    canvasObject.rotate((Math.PI / 180) * anglePosition[pos])
    imgSrc && canvasObject.image(imgSrc, 0, 0, imgWidth, imgHeigth)
    canvasObject.rotate((-Math.PI / 180) * anglePosition[pos])
    canvasObject.translate(-(imgX + imgWidth / 2), -(imgY + imgWidth / 2))
    canvasObject.imageMode('corner')
  }

  const setup = (canvasObject: P5Types, canvasRefParent: Element) => {
    canvasObject
      .createCanvas(canvaDimensionWidth, canvaDimensionHeigth)
      .parent(canvasRefParent)
    canvasObject.stroke('#f6f6f6')

    mainProductImage = canvasObject.loadImage(mainCanvasImage)

    selectedProductsAssembleBracelet.forEach((product, index) => {
      if (product?.imageCanva) {
        adittionalProductsImages.push({
          imgSrc: canvasObject.loadImage(product.imageCanva),
          pos: product?.index ?? index,
        })
      }
    })

    if (isLoading) {
      setIsLoading(false)
    }
  }

  const draw = (canvasObject: P5Types) => {
    canvasObject.background('#f6f6f6')
    canvasObject.imageMode('center')
    canvasObject.image(
      mainProductImage,
      canvasObject.width / 2,
      canvasObject.height / 2,
      canvaBraceletDimension,
      canvaBraceletDimension
    )
    canvasObject.imageMode('corner')

    adittionalProductsImages.forEach((ProductImage) => {
      if (ProductImage.imgSrc) {
        drawRotateImage(canvasObject, ProductImage)
      }
    })

    canvasObject.fill(0)
  }

  return (
    <ClientOnly>
      <div
        className="product-render-container"
        style={{
          width: canvaDimensionWidth,
          height: canvaDimensionHeigth,
        }}
      >
        {isLoading && (
          <Skeleton
            width={canvaDimensionWidth - 46}
            height={canvaDimensionHeigth - 46}
            backgroundColor="#F4F4F4"
            borderRadius={20}
            padding={23}
            margin={23}
          />
        )}
        <Suspense fallback={null}>
          <Sketch
            className="SketchCanva-Assemble-Bracelet"
            setup={setup as () => void}
            draw={draw as () => void}
          />
        </Suspense>
      </div>
    </ClientOnly>
  )
}

export default memo(ProductRender)
