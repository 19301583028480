import React from 'react'
import SimilarProducts from 'src/components/product/SimilarProducts/SimilarProducts'
import SimilarProductsNew from 'src/components/product/SimilarProductsNew/SimilarProductsNew'
import type { DataSimilars } from 'src/components/product/SimilarProducts/SimilarProducts'
import { sortSkuVariations } from 'src/utils/product/sortSkuVariations'
import GiftBackValue from 'src/components/product/GiftBackValue'
import { isNewPDP } from 'src/utils/isNewPDP'

import { AddToCartLoadingSkeleton } from './AddCartLoadingSkeleton'
import { HandlePrice, HandleAvailableProducts } from './DetailsSideBar'
import { OutOfStockSection } from './OutOfStockSection'
import type {
  GivexProps,
  HandleAvailableProductsType,
} from './types/HandleAvailableProductsType'
import { ProductSKUPriceRange } from './ProductSKUPriceRange'

export function ProductDetailsHandler({
  addQuantity,
  availableItemsValue,
  breadcrumbs,
  buyProps,
  clusterHighlights,
  hasVariant,
  hasVariantValue,
  isProductAvailable,
  isValidating,
  items,
  orderFormId,
  priceData,
  product,
  removeProductFromWishlist,
  selectedSKU,
  setAddQuantity,
  setIsAssemblePersonlize,
  setIsInventoryModalOpen,
  setIsSaveProductModalOpen,
  setSelectedSkuBracelet,
  wasList,
  setWasList,
  showButtonAssembleYourBracelet,
  similars,
  sku,
  setGivexAttachment,
}: ProductDetailsHandlerType) {
  const { listPrice, lowPrice, seller, price } = priceData
  const priceFinal = price ?? 0

  function handleSimilarProducts() {
    return Object.keys(similars).length ? (
      <>
        {isNewPDP ? (
          <SimilarProductsNew similars={similars} />
        ) : (
          <SimilarProducts similars={similars} />
        )}
      </>
    ) : null
  }

  return (
    <>
      {isValidating ? (
        <AddToCartLoadingSkeleton />
      ) : (
        <>
          {isProductAvailable ? (
            <>
              <ProductSKUPriceRange
                breadcrumbs={breadcrumbs}
                product={product}
              />
              <section className="product-details__values">
                {listPrice > 0 && priceFinal > 0 && (
                  <HandlePrice
                    lowPrice={lowPrice}
                    listPrice={listPrice}
                    productId={sku}
                    clusterHighlights={clusterHighlights}
                    shouldShowinstallment
                  />
                )}
              </section>
              <GiftBackValue listPrice={listPrice} lowPrice={lowPrice} />
              {handleSimilarProducts()}
              <HandleAvailableProducts
                hasVariantValue={hasVariantValue}
                sku={sku}
                breadcrumbs={breadcrumbs}
                hasVariant={sortSkuVariations(hasVariant)}
                product={product}
                setAddQuantity={setAddQuantity}
                availableItemsValue={availableItemsValue}
                orderFormId={orderFormId}
                items={items}
                wasList={wasList}
                setWasList={setWasList}
                isProductAvailable={isProductAvailable}
                buyProps={buyProps}
                removeProductFromWishlist={removeProductFromWishlist}
                setIsSaveProductModalOpen={setIsSaveProductModalOpen}
                setIsInventoryModalOpen={setIsInventoryModalOpen}
                addQuantity={addQuantity}
                seller={seller}
                setIsAssemblePersonlize={setIsAssemblePersonlize}
                showButtonAssembleYourBracelet={showButtonAssembleYourBracelet}
                selectedSKU={selectedSKU}
                setSelectedSkuBracelet={setSelectedSkuBracelet}
                setGivexAttachment={setGivexAttachment}
              />
            </>
          ) : (
            <OutOfStockSection
              price={priceFinal}
              listPrice={listPrice}
              sku={sku}
              productId={product.id}
            />
          )}
        </>
      )}
    </>
  )
}

export type ProductDetailsHandlerType = HandleAvailableProductsType & {
  clusterHighlights: Array<{ id: string | null; name: string | null }> | null
  isValidating: boolean
  priceData: PriceData
  similars: DataSimilars
  setGivexAttachment: React.Dispatch<GivexProps>
}
