import React, { useState, useEffect } from 'react'
import type { BrowserProductQueryQuery } from '@generated/graphql'
import type { InferredType } from 'src/components/sections/ProductDetails/useOffer'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import Button from 'src/components/ui/VivaraButton'
import PinkkArrowUp from 'src/components/icons/PinkArrowUp'
import Edit from 'src/components/icons/Edit'
import ButtonGoTop from 'src/components/ui/Button/ButtonGoTop'

import type { SelectedProductsAssemble } from './types'
import { AssembleResume } from '../AssembleResume/AssembleResume'
import ProductRender from '../Canvas/ProductRender'
import { ProductGallerySelectedAssemble } from '../ProductGallerySelectedAssemble/ProductGallerySelectedAssemble'
import { ProductGalleryAssembleEmpty } from '../ProductGallerySelectedAssemble/ProductGalleryAssembleEmpty'
import { DEFAULT_MAX_ADDITIONAL_ITENS } from './Assembly'

import './AssemblyPage.scss'

interface AccordionAssemblyProps {
  selectedProductsAssembleBracelet: Array<SelectedProductsAssemble | null>
  setSelectedProductsAssembleBracelet: (value: Array<SelectedProductsAssemble | null>) => void
  productMain: BrowserProductQueryQuery['product']
  selectedSkuBracelet: InferredType<
  BrowserProductQueryQuery['product']['isVariantOf']['hasVariant']
  >
  itemsSelectedQtt: number
  onClickAddCardProduct: (product: SelectedProductsAssemble) => Promise<number>
  onClickRemoveProduct: (index: number) => void
  hasChoiceChain: boolean
  onClickAddChain: (product: SelectedProductsAssemble) => Promise<void>
  selectedProductsAssembleBraceletChain: SelectedProductsAssemble | null
  setSelectedProductsAssembleBraceletChain: (value: SelectedProductsAssemble | null) => void
  isFirstClick: boolean
  setIsFirstClick: React.Dispatch<React.SetStateAction<boolean>>
  applyChainFilter: () => void
  finishEdit: () => void
  cancelEdit: () => void
}

export const AccordionAssembly = ({
  selectedProductsAssembleBracelet,
  setSelectedProductsAssembleBracelet,
  productMain,
  selectedSkuBracelet,
  itemsSelectedQtt,
  onClickAddCardProduct,
  onClickRemoveProduct,
  hasChoiceChain,
  onClickAddChain,
  selectedProductsAssembleBraceletChain,
  setSelectedProductsAssembleBraceletChain,
  isFirstClick,
  setIsFirstClick,
  applyChainFilter,
  finishEdit,
  cancelEdit,
}: AccordionAssemblyProps) => {
  const { isMobile, width } = useWindowDimensions()
  const totalItems =
    DEFAULT_MAX_ADDITIONAL_ITENS +
    (selectedProductsAssembleBraceletChain ? 1 : 0)

  const [showUpCanva, setShowUpCanva] = useState(true)
  const [showSelectedAssemble, setShowSelectedAssemble] = useState(false)

  const [backToTopVisible, setBackToTopVisible] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (showSelectedAssemble) {
        window.scrollY > 1250
          ? setBackToTopVisible(true)
          : setBackToTopVisible(false)
      } else {
        window.scrollY > 390
          ? setBackToTopVisible(true)
          : setBackToTopVisible(false)
      }
    })
  }, [showSelectedAssemble])

  const toggleShowUpCanva = () => {
    setShowUpCanva(!showUpCanva)
  }

  const toggleShowSelectedAssemble = () => {
    setShowSelectedAssemble(!showSelectedAssemble)
  }

  const TitleInsideAccordion = () => {
    return (
      <div className="assembly-edition__title">
        <p className="assembly-edition__title-left">editar pulseira</p>
        <p className="assembly-edition__title-right">
          Exclua os pingentes adicionados aqui.
        </p>
      </div>
    )
  }

  const handleCancelEditClick = () => {
    toggleShowSelectedAssemble()
    cancelEdit()
  }

  const handleFinishEditClick = () => {
    showSelectedAssemble && finishEdit()

    toggleShowSelectedAssemble()
  }

  return (
    <>
      <div className="accordion-assembly">
        <div
          className="accordion-assembly__panel canva-panel"
          aria-expanded={showUpCanva}
        >
          <ProductRender
            selectedProductsAssembleBracelet={selectedProductsAssembleBracelet}
            isMobileDimension={isMobile}
            widthDimension={width}
          />
          {!isMobile && (
            <AssembleResume
              productMain={productMain}
              selectedProductsAssembleBracelet={
                selectedProductsAssembleBracelet
              }
              setSelectedProductsAssembleBracelet={setSelectedProductsAssembleBracelet}
              hasChoiceChain={hasChoiceChain}
              selectedProductsAssembleBraceletChain={
                selectedProductsAssembleBraceletChain
              }
              setSelectedProductsAssembleBraceletChain={setSelectedProductsAssembleBraceletChain}
              selectedSkuBracelet={selectedSkuBracelet}
              isFirstClick={isFirstClick}
              setIsFirstClick={setIsFirstClick}
              applyChainFilter={applyChainFilter}
            />
          )}
        </div>
        <div className="accordion-assembly__actions">
          <Button
            className={`accordion-assembly__toggle-button toggle-button-edit ${
              showSelectedAssemble ? 'bold' : 'rotate'
            }`}
            onClick={handleFinishEditClick}
          >
            <Edit />
            {showSelectedAssemble ? 'CONCLUIR' : 'EDITAR'}
            {` (${
              selectedProductsAssembleBracelet.filter((item) => !!item).length +
              (selectedProductsAssembleBraceletChain ? 1 : 0)
            }/${totalItems})`}
          </Button>

          {showSelectedAssemble ? (
            <>
              {!isMobile && <TitleInsideAccordion />}
              <Button
                className="assembly-edition__cancel"
                onClick={handleCancelEditClick}
              >
                Cancelar
              </Button>
            </>
          ) : (
            <Button
              onClick={toggleShowUpCanva}
              className={`accordion-assembly__toggle-button toggle-button-canva canva-control ${
                showUpCanva ? '' : 'rotate'
              }`}
            >
              <span className="accordion-assembly__toggle-button toggle-button-text">
                {showUpCanva ? 'VER PINGENTES' : 'VER PULSEIRA'}
              </span>
              <PinkkArrowUp />
            </Button>
          )}
        </div>
        <div
          className="accordion-assembly__panel selected-products-painel"
          aria-expanded={showSelectedAssemble}
        >
          {isMobile && <TitleInsideAccordion />}
          <div className="accordion-product-selected__content">
            {itemsSelectedQtt === 0 ? (
              <ProductGalleryAssembleEmpty
                showSelectedAssemble={showSelectedAssemble}
                setShowSelectedAssemble={setShowSelectedAssemble}
              />
            ) : (
              <ProductGallerySelectedAssemble
                selectedProductsAssembleBracelet={
                  selectedProductsAssembleBracelet
                }
                onClickAddCardProduct={onClickAddCardProduct}
                onClickRemoveProduct={onClickRemoveProduct}
                onClickAddChain={onClickAddChain}
                selectedProductsAssembleBraceletChain={
                  selectedProductsAssembleBraceletChain
                }
              />
            )}
          </div>
        </div>
      </div>
      {(backToTopVisible || !showUpCanva) && <ButtonGoTop />}
    </>
  )
}
