import type {
  BrowserProductQueryQuery,
  IStoreSelectedFacet,
} from '@generated/graphql'
import axios from 'axios'
import { get } from 'idb-keyval'

import type { ProductsListComparedProps } from '../ComparedProduct'
import { getComparedProductByVisual } from '../utils/getComparedProductsByVisual'

interface BrowserProductQueryQueryWithEdge {
  node: BrowserProductQueryQuery['product']
}

const getComparedProductByPrice = (
  categoryThree: string,
  offers: BrowserProductQueryQuery['product']['offers']
) => {
  const offersPrices = offers?.offers?.[0]
  const price =
    offersPrices?.availability === 'https://schema.org/InStock'
      ? offers?.lowPrice
      : offersPrices?.price

  const calculationPerc = price * 0.2

  return [
    { key: 'category-3', value: categoryThree },
    {
      key: 'price',
      value: `${price - calculationPerc}-to-${price + calculationPerc}`,
    },
  ]
}

const getApiComparedProduct = async (selectedFacets: IStoreSelectedFacet[]) => {
  const session = await get('fs::session')

  return axios
    .post('/api/getProductSearch', {
      selectedFacets,
      channel: session?.channel,
    })
    .catch((error) => {
      console.error(error)
    })
}

export const getComparedProduct = async (
  categoryThree: string,
  product: BrowserProductQueryQuery['product']
) => {
  const selectedFacetsByVisual = getComparedProductByVisual(
    categoryThree,
    product?.specificationGroups,
    product?.offers
  )

  const selectedFacetsByPrice = getComparedProductByPrice(
    categoryThree,
    product?.offers
  )

  const dataPromise = await Promise.all([
    getApiComparedProduct(selectedFacetsByVisual),
    getApiComparedProduct(selectedFacetsByPrice),
  ])

  const productsList = dataPromise?.map((item) => {
    const edges = item?.data?.search?.products?.edges
    const hasPedra = selectedFacetsByVisual?.find(
      (facet) => facet.key === 'pedras'
    )

    const edgesMap = edges?.map(
      (edge: BrowserProductQueryQueryWithEdge) => edge?.node
    ) as Array<BrowserProductQueryQuery['product']>

    const filterProductsWithoutPedras = edgesMap?.filter(
      (productItem, index) => {
        if (hasPedra) return productItem

        const allSpec = productItem?.specificationGroups?.find(
          (spec) => spec.name === 'allSpecifications'
        )

        const productWithPedras = allSpec?.specifications?.some(
          (spec) => spec?.name?.toLocaleLowerCase() === 'pedras'
        )

        if (index === 0 && !hasPedra && productWithPedras) {
          return false
        }

        return productItem
      }
    )

    const filterProducts = filterProductsWithoutPedras?.filter(
      (productItem, index) => {
        return productItem?.id !== product?.id && index < 3
      }
    )

    if (filterProducts?.length) {
      filterProducts?.unshift(product)
    }

    return filterProducts
  })

  const result: ProductsListComparedProps = {
    byVisual: secondPriceFilter(productsList?.[0], selectedFacetsByVisual),
    byPrice: secondPriceFilter(productsList?.[1], selectedFacetsByPrice),
  }

  return result
}

const secondPriceFilter = (productsList: Array<ProductsListComparedProps['byVisual'][0]>, selectedFacets: Array<IStoreSelectedFacet>) => {
    const priceRangeFacet = selectedFacets.find((facet) => facet.key === 'price')?.value
    
    if(!priceRangeFacet) {
      return productsList
    }
    
    const [lowPrice, highPrice] = priceRangeFacet?.split('-to-')

    //ensures that the product lowprice is still below the highprice filter
    const doublePriceRangeFilter = productsList.filter((product) => 
      product.offers.lowPrice > Number(lowPrice) &&
      product.offers.lowPrice < Number(highPrice)
    )

    return doublePriceRangeFilter
}
