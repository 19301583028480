import { useCallback } from 'react'

type Props = {
  event: 'video_start' | 'video_progress' | 'view_complete'
  pdp_version?: string
  video_progress?: string
  video_complete?: boolean
  fullScreen?: boolean
  muted?: boolean
  user_logged: boolean
  user_id: string | null
  pageType: string
}

export const useVideoEvent = () => {
  window.dataLayer = window.dataLayer || []
  const { dataLayer } = window

  const sendVideoEvent = useCallback((props: Props) => {
    dataLayer.push({
      event: props.event,
      params: {
        pdp_version: props.pdp_version,
        video_progress: props.video_progress,
        video_complete: props.video_complete,
        fullScreen: props.fullScreen,
        muted: props.muted,
        user_logged: props.user_logged,
        user_id: props.user_id,
        pageType: props.pageType,
      },
    })
  }, [])

  return { sendVideoEvent }
}
