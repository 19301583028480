import type {
  BrowserProductQueryQuery,
  Specifications,
} from '@generated/graphql'

type SpecGroup =
  | BrowserProductQueryQuery['product']['specificationGroups']
  | null
  | undefined
type Specification = Specifications | null | undefined

const getProductSpecGroup = (
  specificationGroups: SpecGroup,
  specificationGroupName: string
) => {
  if (!specificationGroups) return null

  const emptySpecificationGroups = specificationGroups.length <= 0

  if (emptySpecificationGroups) return null

  const specificationGroupAllSpec = specificationGroups.find(
    (p) => p?.name === 'allSpecifications'
  )

  if (!specificationGroupAllSpec?.specifications) return null

  const specificationGroupMatch: Specification =
    specificationGroupAllSpec.specifications.find(
      (p) => p?.name === specificationGroupName
    )

  return specificationGroupMatch ?? null
}

export default getProductSpecGroup
