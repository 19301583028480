const toggleClassElement = (
  element: string,
  classSet: string,
  isRemove = false
) => {
  const containerLayout = document?.querySelector(element) as HTMLElement

  if (containerLayout) {
    if (!isRemove) {
      containerLayout?.classList?.add(classSet)

      return true
    }

    containerLayout?.classList?.remove(classSet)

    return true
  }

  return false
}

export default toggleClassElement
