import React, { useState } from 'react'
import type { ReactNode } from 'react'
import { useSession } from 'src/sdk/session'
import { useProductAbTestEvent } from 'src/sdk/analytics/hooks/useProductAbTestEvent'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import { isNewPDP } from 'src/utils/isNewPDP'
import LeftArrowBlack from 'src/images/svg/icon-arrow-left-black'

import './expandable.scss'

interface ExpandableProps {
  title: string
  children: ReactNode
  lineAfter?: boolean
}

const Expandable = ({ title, children, lineAfter }: ExpandableProps) => {
  const [expanded, setExpanded] = useState(true)
  const { person } = useSession()
  const { isMobile } = useWindowDimensions()
  const { sendProductAbTestEvent } = useProductAbTestEvent()

  const sendCollectionSelectItemAbTestEventToAnalytics = () => {
    sendProductAbTestEvent({
      pdp_version: isNewPDP ? 'versao_2' : 'versao_1',
      action: 'click_dropdown',
      component: 'dropdown',
      position_interaction: 'meio',
      element_name: 'colecao_do_produto',
      section: 'detalhamento_produto',
      user_id: person?.id ?? null,
      user_logged: !!person?.id,
    })
  }

  const sendDetailsSelectItemAbTestEventToAnalytics = () => {
    sendProductAbTestEvent({
      pdp_version: isNewPDP ? 'versao_2' : 'versao_1',
      action: 'click_dropdown',
      component: 'dropdown',
      position_interaction: 'meio',
      element_name: 'detalhes_do_produto',
      section: 'detalhamento_produto',
      user_id: person?.id ?? null,
      user_logged: !!person?.id,
    })
  }

  const toggleExpanded = (toggleTitle: string) => {
    setExpanded(!expanded)

    if (toggleTitle.toLocaleLowerCase() === 'detalhes') {
      sendDetailsSelectItemAbTestEventToAnalytics()
    }

    if (toggleTitle.toLocaleLowerCase() === 'sobre a coleção') {
      sendCollectionSelectItemAbTestEventToAnalytics()
    }
  }

  return (
    <div className="expandable">
      <div className="expandable-container">
        <div className="expandable-header">
          <h3 className="expandable-header_title">{title}</h3>
          <LeftArrowBlack
            width="10"
            height="15"
            onClick={() => toggleExpanded(title)}
            className={expanded ? 'open' : 'closed'}
          />
        </div>
      </div>
      {expanded && (
        <>
          {(!lineAfter || isMobile) && <div className="expandable-line" />}
          {children}
        </>
      )}
      {lineAfter && !isMobile && <div className="expandable-line" />}
    </div>
  )
}

export default Expandable
