import React, { useEffect, useState } from 'react'
import type { BrowserProductQueryQuery } from '@generated/graphql'

interface ProductBenefitsProps {
  breadcrumbList?: BrowserProductQueryQuery['product']['breadcrumbList']
}

const ProductBenefits = (props: ProductBenefitsProps) => {
  const { breadcrumbList } = props

  const [isSustentabilityMessage, setIsSustentabilityMessage] =
    useState<boolean>(false)

  useEffect(() => {
    if (breadcrumbList?.itemListElement) {
      const validateRender = breadcrumbList?.itemListElement?.some(
        (breadcrumb) =>
          breadcrumb?.item?.indexOf('/life/acessorios') !== -1 ||
          breadcrumb?.item?.indexOf('/vivara/acessorios') !== -1 ||
          breadcrumb?.item?.indexOf('/vivara/relogios') !== -1
      )

      setIsSustentabilityMessage(!validateRender)
    }
  }, [])

  return (
    <div className="product-benefits">
      <ul className="product-benefits__list">
        <li className="benefit-item">
          <a
            href="https://www.vivara.com.br/institucional/politica-garantia"
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            Consulte nossas garantias
          </a>
        </li>
        <li className="benefit-item">Troca gratuita em até 30 dias</li>
        <li className="benefit-item">
          Devolução gratuita e até 7 dias a partir da data de recebimento
        </li>
      </ul>
      {isSustentabilityMessage && (
        <p className="sustentability">
          <span className="icon" />
          <span className="text">
            <strong>Sustentabilidade:</strong> Asseguramos a responsabilidade
            socioambiental desde a origem da matéria-prima das joias. Saiba mais
            sobre{' '}
            <a
              href="https://www.vivara.com.br/institucional/sustentabilidade-planeta"
              className="link"
            >
              nossas certificações aqui.
            </a>
          </span>
        </p>
      )}
    </div>
  )
}

export default ProductBenefits
