import { useCallback } from 'react'

type Props = {
  error_status: boolean
  local: string
  page_url: string
  user_logged: boolean
  user_id: string | null
}

export const useRegionalizedPostalCodeEvent = () => {
  window.dataLayer = window.dataLayer || []
  const { dataLayer } = window

  const sendRegionalizedPostalCodeEvent = useCallback((props: Props) => {
    dataLayer.push({
      event: 'regionalizacao',
      error_status: props.error_status,
      local: props.local,
      page_url: props.page_url,
      user_logged: props.user_logged,
      user_id: props.user_id,
    })
  }, [])

  return { sendRegionalizedPostalCodeEvent }
}
