import React, { useState } from 'react'
import type { BrowserProductQueryQuery } from '@generated/graphql'
import { useSession } from 'src/sdk/session'
import { useProductAbTestEvent } from 'src/sdk/analytics/hooks/useProductAbTestEvent'
import { isNewPDP } from 'src/utils/isNewPDP'
import Description from 'src/components/sections/ProductDescription/Description'

import ProductBenefits from './ProductBenefits'

import './styles.scss'

interface AccordionProps {
  title: string
  product?: BrowserProductQueryQuery['product']
}

const AccordionCompositionAndBenefits = (props: AccordionProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [productSpecification, setProductSpecification] =
    useState<Array<{ Value: string[]; Id: number; Name: string }>>()

  const { person } = useSession()
  const { sendProductAbTestEvent } = useProductAbTestEvent()
  const { title, product } = props

  const formatTitle = (toggleTitle: string) => {
    return toggleTitle
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-zA-Z0-9\s]/g, '')
      .replace(/\s+/g, '_')
  }

  const expandAccordion = () => {
    setIsExpanded(!isExpanded)
  }

  const sendSelectItemAbTestEventToAnalytics = (toggleTitle: string) => {
    sendProductAbTestEvent({
      pdp_version: isNewPDP ? 'versao_2' : 'versao_1',
      action: 'click_dropdown',
      component: 'dropdown',
      position_interaction: 'direita',
      element_name: formatTitle(toggleTitle).toLowerCase(),
      section: 'info_gerais',
      user_id: person?.id ?? null,
      user_logged: !!person?.id,
    })
  }

  if (productSpecification) {
    productSpecification?.forEach((item) => {
      return item?.Id
    })
  }

  return (
    <div
      className={`accordion-pdp ${isExpanded ? 'expanded' : 'not-expanded'}`}
    >
      <div className="accordion-top">
        <p className="accordion-top__fieldName">{title}</p>
        <button
          className={`accordion-top__icon ${
            isExpanded ? 'expanded' : 'not-expanded'
          }`}
          onClick={() => {
            expandAccordion()
            sendSelectItemAbTestEventToAnalytics(title)
          }}
        />
      </div>
      <div
        className={`expandable-content ${isExpanded ? 'visible' : 'hidden'}`}
      >
        {title === 'BENEFÍCIOS VIVARA' ? (
          <ProductBenefits breadcrumbList={product?.breadcrumbList} />
        ) : (
          product && (
            <Description
              compositions={product?.isVariantOf.additionalProperty}
              idProduct={product?.isVariantOf.productGroupID}
              setProductSpecification={setProductSpecification}
              categoriesIds={product?.categoriesIds}
              description=""
            />
          )
        )}
      </div>
    </div>
  )
}

export default AccordionCompositionAndBenefits
