import type { Specifications } from '@generated/graphql'

const getFacetMeasurementValue = (text: string) => {
  const numberReg = /\d+(?:,\d+)?/
  const match = numberReg?.exec(text)

  const value = match?.[0].replace(',', '.')

  return value ? parseFloat(value) : null
}

function formatMeasurement(value: number): string {
  const roundedValue = Number(value?.toFixed(1))

  const stringValue =
    roundedValue % 1 === 0 ? roundedValue?.toString() : roundedValue?.toFixed(1)

  return `${stringValue?.replace('.', '')}-mm`
}

export const generateMeasurements = (facet: Specifications) => {
  if (!facet || !facet?.values?.[0]) return null

  const value = getFacetMeasurementValue(facet?.values?.[0])

  if (!value) return null

  const measurements = []
  const step = 0.1
  let currentUp = value
  let currentDown = value - step
  const MAX = value + 2
  const MIN = value - 2

  while (currentUp <= MAX) {
    const formattedMeasurement = formatMeasurement(currentUp)

    measurements.push(formattedMeasurement)

    currentUp += step
  }

  while (currentDown >= MIN && currentDown > 0) {
    const formattedMeasurement = formatMeasurement(currentDown)

    measurements.push(formattedMeasurement)

    currentDown -= step
  }

  if (!measurements.length) return null

  return measurements
}
