import React, { useEffect, useState, memo } from 'react'
import Slider from 'react-slick'
import sliderSettings from 'src/configs/slider-pdp-sku'

import SelectImage from './SelectImage'
import { SimilarProductSkeleton } from './SimilarProductSkeleton'
import Dropdown from './Dropdown/Dropdown'

import './similar-products.scss'

interface Props {
  [key: string]: DataSimilars
}

export interface DataSimilars {
  [key: string]: {
    [key: string]: SimilarsItem
  }
}

export interface Similar {
  [key: string]: SimilarsItem[]
}

export interface Similars {
  dataSimilars: SimilarsItem[]
}

export interface SimilarsItem {
  name: string
  link: string
  imgLink?: string
  selected: boolean
  type: string
  value: string
  productGroupId: string
  originalTypeName: string
  originalValue: string
  hasStock?: boolean
}

const SimilarProducts = ({ similars }: Props) => {
  const [dataSimilars, setDataSimilars] = useState<Similar>({})

  const data: Similar = {}

  const manipulationSimilarData = () => {
    Object.values(similars)?.forEach((similar, index: number) => {
      const key = Object.keys(similars)[index]
      let selectedSimilar

      data[key] = []

      for (const i in similar) {
        if (similar[i]?.selected) {
          selectedSimilar = similar[i]
          data[key].push(similar[i])
        }

        if (selectedSimilar?.value !== similar[i]?.value) {
          data[key].push(similar[i])
        }
      }
    })
  }

  useEffect(() => {
    setDataSimilars({})
    if (Object.values(similars)?.length) {
      manipulationSimilarData()

      setDataSimilars(data)
    }
  }, [similars])

  return (
    <>
      {dataSimilars && (
        <div className="similarProducts">
          {Object.values(dataSimilars ?? {})?.length ? (
            Object.keys(similars ?? {})?.map((item, index) => {
              const similarsData = Object.values(dataSimilars?.[item] ?? {})

              const similarName = similars[item]
                ? Object.values(similars[item] ?? {})?.find(
                    (similarValue) => !!similarValue.originalTypeName
                  )?.originalTypeName ?? item
                : item

              const dataDropdown = similarsData?.filter(
                (dropItem: SimilarsItem) => dropItem.type === 'dropdown'
              )

              const dataImagens = similarsData?.filter(
                (dataImagem: SimilarsItem) =>
                  dataImagem.type === 'imagem' || dataImagem.type === 'fallback'
              )

              return (
                dataSimilars?.[item] && (
                  <div
                    className={`similarProducts__container ${similarsData?.[0]?.type}-container`}
                    key={`${item}-${index}`}
                    style={
                      Object.keys(similars ?? {})?.length - 1 !== index
                        ? {
                            borderBottom: '1px solid #c4c4c4',
                          }
                        : { borderBottom: '1px solid transparent' }
                    }
                  >
                    <p className="similarProducts__title">{similarName}</p>
                    {dataImagens.length > 0 && (
                      <div
                        className={`similarProducts__image ${dataImagens?.[0]?.type}-image`}
                      >
                        <Slider {...sliderSettings}>
                          {dataImagens?.map((similar) => {
                            return (
                              <SelectImage
                                similar={similar}
                                key={similar.link}
                              />
                            )
                          })}
                        </Slider>
                      </div>
                    )}
                    {dataDropdown.length > 0 && (
                      <Dropdown dataSimilars={dataDropdown} />
                    )}
                  </div>
                )
              )
            })
          ) : (
            <SimilarProductSkeleton />
          )}
        </div>
      )}
    </>
  )
}

export default memo(SimilarProducts)
