import React, { useEffect, useRef, useState } from 'react'
import { useSession } from 'src/sdk/session'
import { useLocation } from '@reach/router'
import { useVideoEvent } from 'src/sdk/analytics/hooks/useVideoEvent'
import { isNewPDP } from 'src/utils/isNewPDP'
import LazyLoad from 'react-lazy-load'

interface Props {
  videoSrcURL: string
  videoTitle: string
  controlsVideo: boolean
  autoVideo: boolean
  soundVideo: boolean
  loopingVideo?: boolean
  videoId?: string
  isTrackingDatalayer?: boolean
}

const ProductVideo = ({
  videoSrcURL,
  videoTitle,
  controlsVideo,
  autoVideo,
  soundVideo,
  loopingVideo,
  videoId,
  isTrackingDatalayer,
}: Props) => {
  const [hasVideoStarted, setHasVideoStarted] = useState<boolean>(false)
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const videoContainerRef = useRef<HTMLDivElement | null>(null)
  const videoElementRef = useRef<HTMLVideoElement | null>(null)
  const { person } = useSession()
  const { href } = useLocation()
  const { sendVideoEvent } = useVideoEvent()
  const percentages = [0, 10, 25, 50, 75, 100]

  const getSentPercentagesVideo = () => {
    const storedPercentages = localStorage.getItem('videoPercentageAchieved')

    return storedPercentages
      ? new Set<number>(JSON.parse(storedPercentages))
      : new Set<number>()
  }

  const handleSaveVideoPercentAchievedStorage = (
    videoPercentageAchieved: Set<number>
  ) => {
    localStorage.setItem(
      'videoPercentageAchieved',
      JSON.stringify(Array.from(videoPercentageAchieved))
    )
  }

  const handleClearVideoPercentAchievedStorage = () => {
    localStorage.removeItem('videoPercentageAchieved')
  }

  const handlePlayVideo = () => {
    const videoElement = videoElementRef.current

    if (videoElement) {
      videoElement.play()
      setIsPlaying(true)

      if (!hasVideoStarted) {
        setHasVideoStarted(true)
        sendVideoEvent({
          event: 'video_start',
          pdp_version: isNewPDP ? 'versao_2' : 'versao_1',
          video_progress: '0%',
          fullScreen: false,
          muted: videoElement.muted,
          video_complete: false,
          user_id: person?.id ?? null,
          user_logged: !!person?.id,
          pageType: href,
        })
      }
    }
  }

  const handlePushToDatalayer = () => {
    if (videoElementRef.current) {
      const video = videoElementRef.current
      const { currentTime } = video
      const { duration } = video
      const percentageViewed = (currentTime / duration) * 100

      if (duration === 0) {
        return
      }

      const videoPercentageAchieved = getSentPercentagesVideo()

      percentages.forEach((percentage) => {
        if (percentage === 0) {
          return
        }

        if (
          percentageViewed >= percentage &&
          !videoPercentageAchieved.has(percentage)
        ) {
          const isComplete = percentage === 100

          sendVideoEvent({
            event: isComplete ? 'view_complete' : 'video_progress',
            pdp_version: isNewPDP ? 'versao_2' : 'versao_1',
            video_progress: `${percentage}%`,
            fullScreen: false,
            muted: video.muted,
            video_complete: isComplete,
            user_id: person?.id ?? null,
            user_logged: !!person?.id,
            pageType: href,
          })

          videoPercentageAchieved.add(percentage)
          handleSaveVideoPercentAchievedStorage(videoPercentageAchieved)
        }
      })

      if (percentageViewed >= 100) {
        handleClearVideoPercentAchievedStorage()
        setHasVideoStarted(false)
      }
    }
  }

  useEffect(() => {
    handleClearVideoPercentAchievedStorage()

    if (!isTrackingDatalayer && !isPlaying) {
      return
    }

    const video = videoElementRef.current

    if (video) {
      video.addEventListener('pause', handlePushToDatalayer)

      return () => {
        video.removeEventListener('pause', handlePushToDatalayer)
      }
    }

    return () => {}
  }, [isTrackingDatalayer, isPlaying])

  useEffect(() => {
    if (!isTrackingDatalayer) {
      return
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (
        videoContainerRef.current &&
        !videoContainerRef.current.contains(event.target as Node)
      ) {
        const videoElement = videoElementRef.current

        if (videoElement && !videoElement.paused) {
          videoElement.pause()
        }
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [isTrackingDatalayer])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0]
        const videoElement = videoElementRef.current

        if (videoElement) {
          if (!entry.isIntersecting && !videoElement.paused) {
            videoElement.pause()
          }
        }
      },
      {
        threshold: 0.25,
      }
    )

    if (videoContainerRef.current) {
      observer.observe(videoContainerRef.current)
    }

    return () => {
      if (videoContainerRef.current) {
        observer.unobserve(videoContainerRef.current)
      }
    }
  }, [])

  return (
    <div ref={videoContainerRef} className="video">
      <LazyLoad>
        <video
          ref={videoElementRef}
          id={videoId ?? ''}
          src={videoSrcURL}
          controls={controlsVideo}
          autoPlay={autoVideo}
          muted={soundVideo}
          loop={isTrackingDatalayer ? false : loopingVideo}
          onPlay={handlePlayVideo}
          title={videoTitle}
          playsInline
          preload="metadata"
        >
          <source src={videoSrcURL} type="video/mp4" />
          <track
            default
            kind="captions"
            srcLang="pt-br"
            label="portuguese_captions"
          />
        </video>
      </LazyLoad>
    </div>
  )
}

export default ProductVideo
