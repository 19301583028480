import React from 'react'
import { useSession } from 'src/sdk/session'
import { useProductAbTestEvent } from 'src/sdk/analytics/hooks/useProductAbTestEvent'
import { isNewPDP } from 'src/utils/isNewPDP'
import { Link } from 'gatsby'
import { Image } from 'src/components/ui/Image'

import type { SimilarsItem } from '../SimilarProductsNew'

import './select-similar.scss'

export interface Props {
  similar: SimilarsItem
}

const SelectImage = ({ similar }: Props) => {
  const { person } = useSession()
  const { sendProductAbTestEvent } = useProductAbTestEvent()
  const { type, link, selected, imgLink, name, hasStock, originalTypeName } =
    similar

  const value = similar?.value?.replaceAll('_', ' ')
  const title = value?.charAt(0)?.toUpperCase() + value?.slice(1) || ''

  const sendSelectItemAbTestEventToAnalytics = () => {
    sendProductAbTestEvent({
      pdp_version: isNewPDP ? 'versao_2' : 'versao_1',
      action: 'click',
      component: 'botao',
      position_interaction: 'direita',
      element_name: `selecionar_${originalTypeName}`,
      section: 'info_gerais',
      user_id: person?.id ?? null,
      user_logged: !!person?.id,
    })
  }

  return (
    <button
      className="select-image__thumb-button"
      onClick={sendSelectItemAbTestEventToAnalytics}
    >
      <Link
        className={`select-image__link ${type} ${hasStock ? '' : 'noStock'}`}
        to={link}
        target="_self"
        rel="noopener noreferrer"
        title={title}
        data-link-sku
      >
        {imgLink && (
          <Image
            className={`select-image__link-img ${selected ? 'selected' : ''}`}
            baseUrl={imgLink?.replace('http:', 'https:')}
            alt={name}
            aspectRatio={1}
            layout="constrained"
            loading="lazy"
            options={{
              fitIn: true,
            }}
          />
        )}
      </Link>
    </button>
  )
}

export default SelectImage
