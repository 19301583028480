import React, { useMemo } from 'react'
import useWindowDimensions from 'src/hooks/useWindowDimensions'

import './stamps.scss'

type ProductVerticalPosition = '1' | '2'

type ProductHorizontalPosition = '1' | '2' | '3'

type StampsCollection = {
  collectionName: string
  collectionID: string
  collectionShowProduct: boolean
  collectionProductSettings?: {
    collectionProductVerticalPosition: ProductVerticalPosition
    collectionProductHorizontalPosition: ProductHorizontalPosition
    collectionProductDesktopWidth: string
    collectionProductDesktopHeight: string
    collectionProductMobileWidth: string
    collectionProductMobileHeight: string
    collectionProductDesktopFontSize: string
    collectionProductMobileFontSize: string
    collectionProductDesktopVisibility?: boolean
    collectionProductMobileVisibility?: boolean
  }
}

export type StampsItem = {
  label: string
  fontFamily: '1' | '2'
  fontWeight?: string
  fontLetterSpacing?: string
  fontColor: string
  backgroundColor?: string
  borderRadius: string
  borderSize: string
  borderColor?: string
  link?: string
  collections: StampsCollection[]
}

interface StampsProps {
  allItems: StampsItem[] | undefined
  collections: Array<{
    id: string | null
    name: string | null
  }> | null
}

const Stamps: React.FC<StampsProps> = ({ allItems, collections }) => {
  const baseClass = 'product-stamp'
  const { isMobile } = useWindowDimensions()

  const calculatePosition = (
    productVerticalPosition: ProductVerticalPosition,
    productHorizontalPosition: ProductHorizontalPosition
  ) => {
    const styles: React.CSSProperties = {
      top: 'auto',
      bottom: 'auto',
      left: 'auto',
      right: 'auto',
      transform: '',
    }

    if (productVerticalPosition === '1') {
      styles.top = '22px'
    } else if (productVerticalPosition === '2') {
      styles.bottom = '22px'
    }

    if (productHorizontalPosition === '1') {
      styles.left = '0'
    } else if (productHorizontalPosition === '2') {
      styles.left = '50%'
      styles.transform = 'translateX(-50%)'
    } else if (productHorizontalPosition === '3') {
      styles.right = '0'
    }

    return styles
  }

  const collectionItemsMemoized = useMemo(() => {
    if (!allItems || !collections) {
      return []
    }

    return allItems.filter((item) =>
      item.collections.some((collection) =>
        collections.some((col) => col.id === collection.collectionID)
      )
    )
  }, [allItems, collections])

  return (
    <>
      {collectionItemsMemoized.length > 0 && (
        <div className="stamps-container">
          {collectionItemsMemoized.map((item, index) => {
            const occupiedPositions = new Set<string>()

            return (
              <div key={index} className="stamps-wrapper">
                {item.collections.map((collection, idx) => {
                  const { collectionProductSettings } = collection

                  if (
                    !collection.collectionShowProduct ||
                    !collectionProductSettings
                  ) {
                    return null
                  }

                  const {
                    collectionProductVerticalPosition,
                    collectionProductHorizontalPosition,
                    collectionProductDesktopVisibility,
                    collectionProductMobileVisibility,
                    collectionProductDesktopFontSize,
                    collectionProductMobileFontSize,
                    collectionProductDesktopWidth,
                    collectionProductDesktopHeight,
                    collectionProductMobileWidth,
                    collectionProductMobileHeight,
                  } = collectionProductSettings

                  const isVisible = isMobile
                    ? collectionProductMobileVisibility
                    : collectionProductDesktopVisibility

                  if (!isVisible) {
                    return null
                  }

                  const styles = calculatePosition(
                    collectionProductVerticalPosition,
                    collectionProductHorizontalPosition
                  )

                  const positionKey = `${collectionProductVerticalPosition}-${collectionProductHorizontalPosition}`

                  if (occupiedPositions.has(positionKey)) {
                    return null
                  }

                  occupiedPositions.add(positionKey)

                  return (
                    <div
                      key={idx}
                      data-collection={collection.collectionID}
                      className={baseClass}
                      style={{
                        ...styles,
                        position: 'absolute',
                        fontSize: isMobile
                          ? `${collectionProductMobileFontSize}px`
                          : `${collectionProductDesktopFontSize}px`,
                        fontWeight: item.fontWeight || '500',
                        letterSpacing: `${item.fontLetterSpacing || '0'}px`,
                        color: item.fontColor,
                        backgroundColor: item.backgroundColor,
                        width: isMobile
                          ? `${collectionProductMobileWidth}px`
                          : `${collectionProductDesktopWidth}px`,
                        height: isMobile
                          ? `${collectionProductMobileHeight}px`
                          : `${collectionProductDesktopHeight}px`,
                        borderRadius: `${item.borderRadius}px`,
                        border:
                          item.borderSize && item.borderColor
                            ? `${item.borderSize}px solid ${item.borderColor}`
                            : 'none',
                      }}
                    >
                      {item.link ? (
                        <a
                          href={item.link}
                          rel="noopener"
                          style={{ color: item.fontColor }}
                        >
                          {item.label}
                        </a>
                      ) : (
                        <span>{item.label}</span>
                      )}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}

export default Stamps
