import React, { useLayoutEffect, useState } from 'react'
import type { BrowserProductQueryQuery } from '@generated/graphql'
import { useSession } from 'src/sdk/session'
import { useProductAbTestEvent } from 'src/sdk/analytics/hooks/useProductAbTestEvent'
import { isNewPDP } from 'src/utils/isNewPDP'
import BuyButton from 'src/components/ui/BuyButton'
import toggleClassElement from 'src/utils/toggleClassElement'

import { HandlePrice } from '../../DetailsSideBar'

import './product-details-fixed-bar.scss'

interface IProductDetailsFixedBar {
  product: BrowserProductQueryQuery['product']
  priceData: PriceData
  selectedSKU: BrowserProductQueryQuery['product']
  setIsSaveProductModalOpen?: React.Dispatch<React.SetStateAction<boolean>>
  setWasList?: React.Dispatch<React.SetStateAction<string | undefined>>
  buyProps: {
    onClick: (e: React.MouseEvent) => void
    'data-testid': string
    'data-sku': string | undefined
    'data-seller': string | undefined
  }
}

const baseClass = 'productDetailsFixedBar'

function ProductDetailsFixedBar({
  product,
  priceData,
  selectedSKU,
  buyProps,
}: IProductDetailsFixedBar) {
  const [scrollDirection, setScrollDirection] = useState<string>('scroll-up')
  const { sku } = selectedSKU
  const { clusterHighlights } = product
  const { availability, listPrice, lowPrice, price } = priceData
  const { person } = useSession()
  const { sendProductAbTestEvent } = useProductAbTestEvent()
  const priceFinal = price ?? 0
  const isProductAvailable = availability === 'https://schema.org/InStock'

  const sendAddToCartSelectItemAbTestEventToAnalytics = () => {
    sendProductAbTestEvent({
      pdp_version: isNewPDP ? 'versao_2' : 'versao_1',
      action: 'click',
      component: 'botao',
      position_interaction: 'inferior',
      element_name: 'adicionar_a_sacola',
      section: 'secao_inferior',
      user_id: person?.id ?? null,
      user_logged: !!person?.id,
    })
  }

  useLayoutEffect(() => {
    const handleScroll = () => {
      const buyButtonPdp: HTMLButtonElement | null =
        document.querySelector('#pdp_buy-button')

      const currentScrollTop =
        window?.scrollY || document?.documentElement.scrollTop

      const rect = buyButtonPdp?.getBoundingClientRect()
      const isVisible = rect
        ? currentScrollTop >= 648
          ? rect.top >= 0 && rect.bottom <= window.innerHeight
          : true
        : false

      if (!isVisible) {
        setScrollDirection('scroll-down')
        toggleClassElement('#layout', 'visibleLayout', false)
      } else if (isVisible) {
        setScrollDirection('scroll-up')
        toggleClassElement('#layout', 'visibleLayout', true)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className={`a-${baseClass} ${scrollDirection}`}>
      <div className={baseClass}>
        <div className={`${baseClass}-title`}>
          <h2 className={`${baseClass}-title__text`}>{product?.name}</h2>
        </div>
        <div className={`${baseClass}-priceList`}>
          {listPrice > 0 && priceFinal > 0 && (
            <HandlePrice
              lowPrice={lowPrice}
              listPrice={listPrice}
              productId={sku}
              clusterHighlights={clusterHighlights}
              shouldShowinstallment={false}
            />
          )}
        </div>
        <div className={`${baseClass}-buttonsActions`}>
          <button onClick={sendAddToCartSelectItemAbTestEventToAnalytics}>
            <div>
              <BuyButton disabled={!isProductAvailable} {...buyProps}>
                {isProductAvailable ? 'ADICIONAR À SACOLA' : 'Indisponível'}
              </BuyButton>
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}

export default ProductDetailsFixedBar
