import './components/styles.scss'

import React, { useState, useEffect, lazy } from 'react'
import DetailsSideBar from 'src/components/sections/ProductDetails/DetailsSideBar'
import { getProductKitLook } from 'src/components/product/ProductKitLook/getProductKitLook'
import { View } from 'src/components/View'
import { Skeleton } from '@acctglobal/skeleton'
import { useProduct } from 'src/sdk/product/useProduct'
import type { FC } from 'react'
import type { PDPProps, PDPServerData } from 'src/pages/[slug]/p'
import type { BrowserProductQueryQuery } from '@generated/graphql'
import type { InferredType } from 'src/components/sections/ProductDetails/useOffer'
import { AddToCartLoadingSkeletonKit } from 'src/components/sections/ProductDetails/AddToCartLoadingSkeletonKit'
import HeaderFixedBar from 'src/components/sections/ProductDetails/HeaderFixedBar'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import { useSelectedSKU } from 'src/contexts/ProductContext/useProductContext'
import useOffer from 'src/components/sections/ProductDetails/useOffer'
import type { DataSimilars } from 'src/components/product/SimilarProducts/SimilarProducts'
import lazyRetry from 'src/utils/lazyRetry'
import axios from 'axios'
import { useDetailsSpecificationsContext } from 'src/contexts/details-specification-context'
import { updateSimilarsByRegionId } from 'src/components/product/SimilarProducts/utils'
import type { IOProduct } from 'src/components/sections/PlpSellerStore/types'
import { filterBuyTogetherAvailableProducts } from 'src/components/product/BuyTogether/BuyTogether'
import { get } from 'idb-keyval'
import { isNewPDP } from 'src/utils/isNewPDP'

import Assembly from '../../components/AssembleYourBracelet/Assembly/Assembly'
import AboveTheFold from './components/AboveTheFold'
import BelowTheFold from './components/BelowTheFold'
import Seo from './components/Seo'
import PdpCms from './components/PdpCms/PdpCms'

const ProductKitLookDetails = lazy(() =>
  lazyRetry(
    () => import('src/components/product/ProductKitLook'),
    'ProductKitLookDetails'
  )
)

const ViewComponents = {
  seo: Seo,
  above: AboveTheFold,
  below: BelowTheFold,
} as const

export type DataPdp = {
  data: Array<{ sections: Array<{ name: string; data: any }> }>
}

interface ExtendedPdpData extends PDPServerData {
  dataPdp: DataPdp
}

function isElementInViewport(element: Element | null) {
  if (!element) {
    return false
  }

  const rect = element.getBoundingClientRect()

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

const getCmsData = async (
  setPreview: React.Dispatch<
    React.SetStateAction<
      Array<{
        name: string
        data: any
      }>
    >
  >,
  SearchParams: URLSearchParams
) => {
  const contentType = SearchParams.get('contentType')
  const documentId = SearchParams.get('documentId')
  const versionId = SearchParams.get('versionId')

  if (contentType && documentId && versionId) {
    await axios
      .post('/api/getCmsContent', {
        contentType,
        documentId,
        versionId,
      })
      .then((response) => {
        setPreview(response?.data?.sections)
      })
      .catch((error) => {
        console.error('Error -> ', error)
      })
  }
}

const ProductView: FC<PDPProps> = (props) => {
  const { setDetailsSpecifications } = useDetailsSpecificationsContext()
  const [isAssemblePersonlize, setIsAssemblePersonlize] = useState(false)
  const [showButtonAssembleYourBracelet, setShowButtonAssembleYourBracelet] =
    useState<boolean>(false)

  const [selectedSkuBracelet, setSelectedSkuBracelet] =
    useState<
      InferredType<
        | BrowserProductQueryQuery['product']['isVariantOf']['hasVariant']
        | undefined
      >
    >()

  const [productDataIsKit, setProductDataIsKit] =
    useState<ProductKitLookProps>()

  const { serverData } = props

  if (!serverData?.product) {
    throw new Error('Stale product not found')
  }

  // Stale while revalidate the product for fetching the new price etc
  const { data: productData } = useProduct(serverData?.product?.id, {
    product: serverData?.product,
  } as unknown as BrowserProductQueryQuery)

  if (!productData) {
    throw new Error('Product data not found')
  }

  const {
    dataPdp,
    similars: serverSimilars,
    showTogether,
  } = serverData as ExtendedPdpData

  const [pdpData, setDataPdp] = useState<Array<{ name: string; data: any }>>(
    dataPdp?.data?.[0]?.sections
  )

  const [similars, setSimilars] = useState<DataSimilars>(serverSimilars)

  const { product } = productData

  const selectedSKU = useSelectedSKU(
    product
  ) as BrowserProductQueryQuery['product']

  const priceData = useOffer(product, selectedSKU) as PriceData

  const [buyTogetherProducts, setBuyTogetherProducts] = useState<IOProduct[]>(
    []
  )

  const path = window?.location?.pathname

  const getBuyTogether = async () => {
    const session = await get('fs::session')
    const regionId = session?.channel
      ? JSON.parse(session?.channel)?.regionId
      : ''

    const buyTogetherProductsIds: string[] = []

    showTogether?.forEach((item: { productId: string }) => {
      buyTogetherProductsIds?.push(item?.productId)
    })

    try {
      const { data: productsByRegion } = await axios.get(
        `/api/getProductDataByRegionId?requestProductIdsParameter=${encodeURIComponent(
          buyTogetherProductsIds?.join(';')
        )}&regionId=${regionId}`
      )

      setBuyTogetherProducts(
        filterBuyTogetherAvailableProducts(productsByRegion ?? [])
      )
    } catch (error) {
      console.error('error getProductDataByRegionId')
      setBuyTogetherProducts(filterBuyTogetherAvailableProducts([]))
    }
  }

  useEffect(() => {
    const { availability } = priceData

    if (availability === 'https://schema.org/InStock') {
      setBuyTogetherProducts([])
      getBuyTogether()
    }
  }, [path, selectedSKU])

  useEffect(() => {
    const SearchParams = new URLSearchParams(window?.location?.search)

    if (SearchParams) {
      getCmsData(setDataPdp, SearchParams)
    }
  }, [])

  useEffect(() => {
    if (product?.isVariantOf?.additionalProperty) {
      const isProductFromAssembleYourBracelet =
        product?.isVariantOf?.additionalProperty?.some(
          (property) =>
            property.name === 'Monte Sua Pulseira' &&
            (property.value === 'Pulseira com Corrente' ||
              property.value === 'Pulseira sem Corrente')
        )

      setShowButtonAssembleYourBracelet(isProductFromAssembleYourBracelet)
    }
  }, [product])

  const handleReloadPage = (event: PageTransitionEvent) => {
    if (event.persisted) {
      window.location.reload()
    }
  }

  useEffect(() => {
    if (isAssemblePersonlize && selectedSkuBracelet) {
      window.addEventListener('pageshow', handleReloadPage)
    } else {
      window.removeEventListener('pageshow', handleReloadPage)
    }
  }, [isAssemblePersonlize, selectedSkuBracelet])

  useEffect(() => {
    const productSlug = window.location.pathname.split('/')?.[1]

    getProductKitLook(productSlug).then((response) =>
      setProductDataIsKit(response)
    )
  }, [serverData?.product?.id])

  const [isVisibleDetails, setIsVisibleDetails] = useState(false)

  useEffect(() => {
    if (productDataIsKit !== undefined) {
      setIsVisibleDetails(true)
    }
  }, [productDataIsKit])

  const MOBILE_DEFAULT_BUYBUTTON_POSITION = 800
  const DESKTOP_DEFAULT_MENU_POSITION = 150
  const { isMobile } = useWindowDimensions()
  const [showProductBar, setShowProductBar] = useState(false)

  const handleScroll = () => {
    const buyButton = document.getElementById('pdp_buy-button')
    const threshold = isMobile
      ? MOBILE_DEFAULT_BUYBUTTON_POSITION
      : DESKTOP_DEFAULT_MENU_POSITION

    const shouldShowProductBar = !isMobile
      ? window.scrollY > threshold
      : window.scrollY > threshold && !isElementInViewport(buyButton)

    setShowProductBar(shouldShowProductBar)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    setSimilars(serverSimilars)
    updateSimilarsByRegionId(serverSimilars, similars, setSimilars)
  }, [serverSimilars])

  useEffect(() => {
    if (!serverData?.productDetailsSpecifications) {
      return
    }

    setDetailsSpecifications(serverData?.productDetailsSpecifications)
  }, [serverData?.productDetailsSpecifications])

  return (
    <>
      {isAssemblePersonlize && selectedSkuBracelet ? (
        <Assembly
          productMain={product}
          selectedSkuBracelet={selectedSkuBracelet}
        />
      ) : (
        <div className="pdp-container">
          <View
            {...ViewComponents}
            data={{ ...props, product, buyTogetherProducts }}
          />
          {!isVisibleDetails ? (
            <Skeleton width="100%" height={589} backgroundColor="#F4F4F4" />
          ) : (
            <>
              {productDataIsKit !== undefined ? (
                <>
                  {productDataIsKit?.isKit ? (
                    <ProductKitLookDetails
                      product={productDataIsKit}
                      promotionBarProduct={product}
                      priceData={priceData}
                      selectedSKU={selectedSKU}
                    />
                  ) : (
                    <>
                      <DetailsSideBar
                        setIsAssemblePersonlize={setIsAssemblePersonlize}
                        product={product}
                        priceData={priceData}
                        selectedSKU={selectedSKU}
                        showButtonAssembleYourBracelet={
                          showButtonAssembleYourBracelet
                        }
                        setSelectedSkuBracelet={setSelectedSkuBracelet}
                        similars={similars}
                        setShowProductBar={setShowProductBar}
                        buyTogetherProducts={buyTogetherProducts}
                      />
                      {isMobile && !isNewPDP && (
                        <HeaderFixedBar
                          showProductBar={showProductBar}
                          product={product}
                          priceData={priceData}
                          selectedSKU={selectedSKU}
                        />
                      )}
                    </>
                  )}
                </>
              ) : (
                <div
                  style={{ marginTop: '4rem', height: '620', width: '100%' }}
                >
                  <AddToCartLoadingSkeletonKit />
                </div>
              )}
            </>
          )}
        </div>
      )}
      <PdpCms sections={pdpData} />
    </>
  )
}

export default ProductView
