import { navigate } from 'gatsby'
import React, { useCallback } from 'react'
import { normalizeText } from 'src/utils/normalizeText'

interface AboutCollectionProps {
  collections: Array<{
    name?: string | null
    description?: string | null
    urlImage?: string | null
    altImage?: string | null
    id?: string | null
  }>
  isPDPLife: boolean
}

const AboutCollection = (props: AboutCollectionProps) => {
  const { collections, isPDPLife } = props

  const handleGoToCollection = useCallback(
    (collectionName: string) => {
      const collection = normalizeText(collectionName)
        .replace(/ /g, '-')
        .toLowerCase()

      const link = `/colecao/${isPDPLife ? 'life-' : ''}${collection}`

      navigate(link)
    },
    [isPDPLife]
  )

  return (
    <>
      {collections?.map((collection) => (
        <div key={collection.id} className="about-collection">
          <div className="about-collection-text">
            <h2 className="about-collection-text__title">{collection?.name}</h2>
            <div className="about-collection-text__description">
              {collection?.description}
            </div>
            <button
              className="about-collection-text__button-collection"
              onClick={() => handleGoToCollection(collection?.name ?? '')}
            >
              Ver toda a coleção
            </button>
          </div>
          {collection?.urlImage && (
            <img
              src={collection?.urlImage}
              className="about-collection-image"
              alt={collection?.altImage ?? 'Imagem sobre a coleção'}
            />
          )}
        </div>
      ))}
    </>
  )
}

export default AboutCollection
