import React from 'react'

import type { SimilarsItem } from '../SimilarProductsNew'

interface SimilarTitleProps {
  dataDropdown: SimilarsItem[]
  similarName: string
  openDropdown: boolean
  setOpenDropdown: React.Dispatch<React.SetStateAction<boolean>>
}

const SimilarTitle = (props: SimilarTitleProps) => {
  const { dataDropdown, similarName, openDropdown, setOpenDropdown } = props

  const selectedItem = dataDropdown?.filter((item) => item?.selected)[0]

  return dataDropdown?.length > 0 ? (
    <div className="dropdown-container__wrapper">
      <p className="dropdown-container__wrapper-title">Comprimento (CM)</p>
      <p className="dropdown-container__wrapper-selected">
        {selectedItem?.originalValue?.replace(' cm', '')}
      </p>
      <button
        className={`dropdown-container__wrapper-arrow ${
          openDropdown ? 'open' : 'closed'
        }`}
        onClick={() => setOpenDropdown(!openDropdown)}
      />
    </div>
  ) : (
    <p className="imagem-container__title">{similarName}</p>
  )
}

export default SimilarTitle
