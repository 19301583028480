import React, { memo } from 'react'

import type { SimilarsItem } from '../SimilarProductsNew'

import './length-similars.scss'

interface LengthSimilarsProps {
  dataSimilars: SimilarsItem[]
}

function filterDataSimilars(dataSimilars: SimilarsItem[]) {
  return dataSimilars
    ?.map((item) => {
      return {
        ...item,
        originalValue: item.originalValue.replace(' cm', ''),
      }
    })
    .sort(
      (a, b) => parseInt(a.originalValue, 10) - parseInt(b.originalValue, 10)
    )
}

const LengthSimilars = (props: LengthSimilarsProps) => {
  const { dataSimilars } = props

  return (
    <div className="similars-grid">
      {filterDataSimilars(dataSimilars)?.map((similar, index) => (
        <button
          key={index}
          className={`similars-grid__item ${
            similar?.selected ? 'selected' : ''
          } ${similar?.hasStock ? 'has-stock' : 'not-stock'}`}
          onClick={() => {
            window.location.pathname = similar?.link
          }}
          disabled={!similar?.hasStock}
        >
          {similar?.originalValue}
        </button>
      ))}
    </div>
  )
}

export default memo(LengthSimilars)
